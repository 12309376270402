import Masonry from "masonry-layout";
const { littlefoot } = require("littlefoot");

const _lf = littlefoot({
  allowDuplicates: false,
});

// Setup Masonry layouts
let containers = document.getElementsByClassName("gallery");

const masonries = [];

for (let i = 0; i < containers.length; i++) {
  let msnry = new Masonry(containers[i], {
    itemSelector: ".gallery-item",
    gutter: 0,
    percentPosition: true,
  });

  masonries.push(msnry);
}
